import { Button } from '@/components/ui/button'
import { Container } from './Container'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-white py-32"
    >
      <Container className="relative">
        <div className="mx-auto flex max-w-lg flex-col justify-center text-center">
          <h2 className="font-display text-3xl tracking-tight sm:text-4xl text-primary">
            Get started in one click
          </h2>
          <p className="mt-4 text-lg tracking-tight text-primary">
            Take a quick demo or start a Retro with your team. We know
            you&apos;ll love it. It&apos;s one click away.
          </p>
          <div className="flex justify-center">
            <Button isPrimary className="mt-10">
              <a target="_blank" rel="noopener noreferrer" href="/retro">
                Start a Retro
              </a>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
