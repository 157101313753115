import { Container } from '@/components/Container'

const faqs = [
  [
    {
      question: 'Is tinycatch free?',
      answer:
        'Yes, using the board is absolutely free. You can create as many retros as you want and invite as many team members as you want. We do offer a paid plan for additional features.',
    },
    {
      question: 'How many Teammates does tinycatch support?',
      answer: `It'll support your entire team, although, it's recommended to have around 8 teammates for an effective Retro.`,
    },
    {
      question: 'Do you accept credit cards?',
      answer:
        'Yes, we accept credit cards and can provide regular invoices for your team.',
    },
  ],
  [
    {
      question: `How's my company data handled?`,
      answer: `We take security and data privacy seriously. You can check out our security page for details. We can provide security policies and reports upon request.`,
    },
    {
      question: 'How many retros can I create before the paid plan?',
      answer: `You can have unlimited retros. That's right, unlimited retros! It's for teams of all shapes and sizes. The paid plans are there when you need additional functionality.`,
    },
    {
      question: 'What are the limitations of the free plan?',
      answer:
        'You can view the differences between the plans on our pricing page. The paid plans allow you to have multiple teams, unlimited history, one-click exports, and more.',
    },
  ],
  [
    {
      question: 'I already have a Retro app, can I import my data?',
      answer:
        'Yes, on the enterprise plan, we will import your data for you. Alternatively, you can import your data through a CSV file.',
    },
    {
      question: 'I have a feature request, how do I submit it?',
      answer: `You can submit a feature request through our help page. We're glad to hear feedback. We will review it and get back to you as soon as possible.`,
    },
    {
      question: 'I found a bug, how do I report it?',
      answer:
        'You can report a bug through our help page. We will review it and get back to you as soon as possible.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden py-20 sm:py-32 bg-white"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-gray-700">
            These are our commonly asked questions. If you&apos;re looking for
            more, you can throw us a message through our{' '}
            <a href="/help" className="underline">
              help page
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-gray-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-gray-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
