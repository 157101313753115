// import { Button } from '@/components/ui/button'
// import Link from 'next/link'
// import logoMirage from '@/images/logos/mirage.svg'
// import logoStatamic from '@/images/logos/statamic.svg'
// import logoStaticKit from '@/images/logos/statickit.svg'
// import logoTransistor from '@/images/logos/transistor.svg'
// import Image from 'next/image'
// import Particles from '../Particles'
// import { useEffect } from 'react'

// export const Header = ({ handleRegister }) => {
//   return (
//     <header className="relative pb-32 pt-8 bg-white">
//       <Particles />
//       <div
//         className={`max-w-4xl mx-auto mt-8 py-16 md:py-28 px-4 sm:px-6 lg:px-8`}
//       >
//         <h2
//           className={`font-sans font-bold text-4xl md:text-5xl lg:text-6xl text-center leading-snug text-gray-1000`}
//         >
//           Better error reporting, <br></br>speed, and clarity.
//         </h2>
//         <div className={`max-w-xl mx-auto`}>
//           <h1
//             className={`m-4 md:my-10 text-gray-500 text-center font-light text-xl md:text-2xl`}
//           >
//             A <i>tiny</i> error reporter<br></br>built for the web.
//           </h1>
//         </div>
//         <div
//           className={`flex flex-col sm:flex-row justify-center items-center w-full mx-auto`}
//         >
//           <Link href="/retro">
//             <Button isPrimary>Get Started</Button>
//           </Link>
//           <span className={`m-2 md:m-4`}>or</span>
//           <div className="bg-white rounded">
//             <Button isSecond>Why is tiny better?</Button>
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }
import React from 'react'
import { Button } from '@/components/ui/button'
import { Icon } from '@iconify/react'

// import FadeInImage from './fade-in-image'

export const Header = () => {
  return (
    <div className="relative flex h-dvh w-full flex-col overflow-hidden bg-white -my-0 md:-my-20">
      <main className="container mx-auto flex flex-1 flex-col items-center justify-center overflow-hidden px-8">
        <section className="z-20 flex flex-col items-center justify-center gap-[18px] sm:gap-6">
          <Button
            className={`h-9 overflow-hidden border-2 border-yellow-600 bg-default-50 px-[18px] py-2 text-small leading-5 font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 via-yellow-200 to-yellow-600`}
          >
            Now in Beta
          </Button>
          <div className="bg-gradient-to-br from-foreground-800 to-foreground-500 bg-clip-text pt-4 text-5xl text-transparent text-center text-[clamp(40px,10vw,44px)] font-bold leading-[1.2] tracking-tighter sm:text-[64px]">
            Better error reporting, <br />
            speed, and clarity.
          </div>
          <p className="text-center font-normal leading-7 text-default-500 sm:w-[466px] sm:text-[18px]">
            A <i>tiny</i> error reporter that's blazingly fast built for the
            web.
          </p>
          <div className="flex flex-col items-center justify-center gap-6 sm:flex-row">
            <Button
              className="h-10 w-[163px] bg-default-foreground px-[16px] py-[10px] text-small font-medium leading-5 text-background"
              radius="full"
            >
              Get Started
            </Button>
            <Button
              className="h-10 w-[163px] border-1 border-default-100 px-[16px] py-[10px] text-small font-medium leading-5"
              endContent={
                <span className="pointer-events-none flex h-[22px] w-[22px] items-center justify-center rounded-full bg-default-100">
                  <Icon
                    className="text-default-500 [&>path]:stroke-[1.5]"
                    icon="solar:arrow-right-linear"
                    width={16}
                  />
                </span>
              }
              radius="full"
              variant="bordered"
            >
              See our plans
            </Button>
          </div>
        </section>
        <div className="pointer-events-none absolute inset-0 top-[-25%] z-10 scale-150 select-none sm:scale-125">
          {/* <FadeInImage
            fill
            priority
            alt="Gradient background"
            src="https://nextuipro.nyc3.cdn.digitaloceanspaces.com/components-images/backgrounds/bg-gradient.png"
          /> */}
        </div>
      </main>
    </div>
  )
}
