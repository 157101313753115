// components/ShinyDivider.tsx
import { clsx } from 'clsx'

export function ShinyDivider({ className }: { className?: string }) {
  return (
    <div className={clsx('relative my-20 w-full bg-gray-100 h-px', className)}>
      <div
        aria-hidden="true"
        className="absolute left-1/2 h-[200px] w-full max-w-[200px] md:max-w-[400px] -translate-x-1/2 pointer-events-none overflow-clip"
      >
        <div className="relative h-full w-full">
          <div
            aria-hidden="true"
            className="absolute -top-1/2 h-full w-full"
            style={{
              background:
                'conic-gradient(from 180deg at 50% 50%, transparent 50%, rgba(0, 0, 0, 0) 50%), radial-gradient(rgba(200, 200, 200, 0.1) 0%, transparent 80%)',
            }}
          ></div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="h-px absolute left-1/2 top-0 w-1/2 -translate-x-1/2 bg-gradient-to-r from-gray-100 via-gray-700 to-gray-100"
      />
    </div>
  )
}
