import React from 'react'
import { Spacer } from '@heroui/spacer'

import {
  Logo1,
  Logo10,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
} from './logos'

import ScrollingBanner from './scrolling-banner'

const logos = [
  {
    key: 'logo-1',
    logo: Logo1,
  },
  {
    key: 'logo-2',
    logo: Logo2,
  },
  {
    key: 'logo-3',
    logo: Logo3,
  },
  {
    key: 'logo-4',
    logo: Logo4,
  },
  {
    key: 'logo-5',
    logo: Logo5,
  },
  {
    key: 'logo-6',
    logo: Logo6,
  },
  {
    key: 'logo-7',
    logo: Logo7,
  },
  {
    key: 'logo-8',
    logo: Logo8,
  },
  {
    key: 'logo-9',
    logo: Logo9,
  },
  {
    key: 'logo-10',
    logo: Logo10,
  },
]

export default function Component() {
  return (
    <section className="mx-auto w-full max-w-6xl px-6 py-10 sm:py-16 lg:px-8 lg:py-20 bg-white">
      <div className="w-full flex flex-col items-center">
        <div className="text-center text-base md:text-[1.125rem] md:leading-[1.5] text-gray-700 font-normal mb-10 max-w-lg">
          Companies of all sizes trust TinyCatch for blazing fast error handling
          and emergency response.
        </div>
      </div>
      <ScrollingBanner shouldPauseOnHover duration={50} gap="40px">
        {logos.map(({ key, logo }) => (
          <div
            key={key}
            className="flex items-center justify-center text-foreground"
          >
            {logo}
          </div>
        ))}
      </ScrollingBanner>
      <Spacer y={12} />
      <ScrollingBanner isReverse shouldPauseOnHover duration={50} gap="40px">
        {logos.map(({ key, logo }) => (
          <div
            key={key}
            className="flex items-center justify-center text-foreground"
          >
            {logo}
          </div>
        ))}
      </ScrollingBanner>
    </section>
  )
}
